import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export interface SettingsQueryData {
  showFeaturedPost: boolean
}

export const useSettingsQuery = (): SettingsQueryData => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      query AppSettingsQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
          frontmatter {
            showFeaturedPost
          }
        }
      }
    `
  )

  return frontmatter
}
